.apply-add-project {
  border-radius: 5px;
  border: 1px dashed #999;
  padding: 4px 10px 4px 10px;
  margin-left: 10px;
}

.replenish-btns {
  height: 60px;
  padding-top: 25px;
  .replenish-btns-child {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      margin-left: 15px;
    }
    .orange-btn {
      background: #F29D1C;
      border-color: #F29D1C;
      color: #fff;
    }
  }
}
.replenish-wrap{
  position: relative;
  .expand-btns{
    cursor: pointer;
    font-size: 12px;
    color: #1890ff;
    position: absolute;
    top: -25px;
    right: 0px;
    z-index: 999;
  }
}