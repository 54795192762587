//合同模板管理
.contract-warp {
    margin-top: 20px;
    position: relative;
    .ant-form-horizontal {
        // margin-left: 45px;
        .ant-form-item {
            display: flex;
            .ant-form-item-label {
                width: 110px;
            }
        }
    }
    .contract-btn-a {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 12px;
        color: #1890ff;
        cursor: pointer;
    }
    .contract-filter {
        width: 100%;
        padding: 0 0 20px 0;
        >h4 {
            padding-top: 6px;
        }
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .ant-btn {
            margin: 0 15px;
        }
    }
    .ant-form-item.contract-change {
        width: 390px;
    }
}

.contract-unfold {
    display: none!important;
}

//审批详情页
.contract-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contract-list-title {
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 800;
}

.contract-details-btn {
    height: 40px;
    .orange-btn {
        background: #F29D1C;
        border-color: #F29D1C;
        color: #fff;
        margin-left: 15px;
    }
    .contract-details-btns {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        // box-shadow: 0px 0px 5px #888888;
        background: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        .contract-details-input {
            position: absolute;
            left: 20px;
            bottom: -5px;
        }
    }
}

.contract-hide {
    display: none;
}

.contract-show {
    display: block;
}

.ant-btn.contract-red {
    border: 1px solid red;
}