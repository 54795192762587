// 发票管理 样式
.invoice-wrap{
  position: relative;
  .invoice-btn-a{
    cursor: pointer;
    font-size: 12px;
    color: #1890ff;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
  }
}
.invoice-form-btn{
  margin-right: 5px;
  color: #439DDD;
  cursor: pointer;
}
.invoice-add-search-area{
  margin-bottom: 20px;
}
.add-newinvoice-detail .orange-btn {
  background: #F29D1C;
  border-color: #F29D1C;
  color: #fff;
}

.add-newinvoice-detail .pro-detail-title {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.add-newinvoice-detail .pro-detail-smailtitle > .ant-descriptions-title {
  font-size: 14px;
  height: 34px;
}

.add-newinvoice-detail .step-wrap {
  padding: 20px 25% 20px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.add-newinvoice-detail .info-box {
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.add-newinvoice-detail .info-box .content {
  line-height: 1.5em;
  height: 1.5em;
}

.add-newinvoice-detail .info-box .info {
  color: #3490FD;
}

.add-newinvoice-detail .btns {
  text-align: center;
  margin: 30px 0;
}

.add-newinvoice-detail .required-label{
  color: red;
}

.add-newinvoice-detail .btns button {
  margin-left: 10px;
}

.add-newinvoice-detail .step-wrap {
  border-bottom: none;
  padding: 20px 0 20px 0;
}

.add-newinvoice-detail .record-list {
  margin: 20px 0;
}

.add-newinvoice-detail .record-list-title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.add-newinvoice-detail .record-title-tip{
  color: #989898;
  margin-bottom: 10px;
}

.add-newinvoice-detail .blank-line {
  height: 10px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin-left: -20px;
}

.add-newinvoice-detail .table-wrap {
  margin: 35px 0;
}

.add-newinvoice-detail .table-wrap .table-wrap-title {
  font-weight: bold;
  padding-bottom: 20px;
}

.add-newinvoice-detail .ask-info {
  margin: 15px 0;
}

.add-newinvoice-detail .ant-descriptions-item-content {
  color: #989898;
}

.add-newinvoice-detail .acount-checked-total{
  margin: 10px 0;
}

.task-bottom-btns{
  text-align: right;
  background: #fff;
  padding: 20px 5px 0px 0;
  .release-choose{
    float: left;
    line-height: 32px;
    margin-left: 15px;
  }
  .cancel-clock{
    float: left;
    margin-top: 3px;
    margin-left: 10px;
  }
}
.add-newinvoice-detail .ant-form-item{
  // width: 25%;
  display: inline-block;
}
.ask-info{
  .ant-descriptions-item{
    vertical-align: top;
  }
}
.upload-hint {
  color:#989898;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}