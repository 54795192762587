.apply-money {
    margin-top: 15px;
    .ant-form-item {
        display: flex;
    }
}

.quittance-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    .orange-btn {
        background: #F29D1C;
        border-color: #F29D1C;
        color: #fff;
    }
    .ant-btn {
        margin-left: 20px
    }
}