.apply-service {
    display: flex;
    align-items: center;
    .apply-service-input.ant-radio-wrapper {
        margin-left: 100px;
    }
    .ant-radio-group {
        display: flex;
        align-items: center;
    }
    
}