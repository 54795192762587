.apply-warp {
    margin-bottom: 80px;
    .service {
        margin-left: 70px;
    }
    .order {
        .ant-col {
            .ant-form-item {
                display: flex;
                .ant-form-item-label {
                    width: 100px;
                }
            }
        }
        .apply-checkbox {
            .ant-form-item-control-wrapper {
                margin-top: 5px;
            }
        }
    }
    .apply-upload {
        display: flex;
        margin-top: 30px;
        .ant-col {
            .ant-upload {
                display: flex;
            }
        }
    }
    .apply-frame {
        display: flex;
        align-items: center;
        margin-top: 25px;
        .ant-form-item-control-wrapper {
            width: 240px;
        }
    }
    .frame-content {
        border: none;
        outline: none;
        border-bottom: 1px solid #999;
        width: 80px;
    }
    .apply-client {
        .ant-row {
            display: flex;
        }
    }
    .apply-abs {
        position: absolute;
        left: 65px;
        top: -49px;
    }
    .apply-title {
        font-weight: 800;
        color: #000;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .apply-serial {
        margin-left: 15PX;
        margin-bottom: 10PX;
        font-weight: 600;
        &>span {
            color: #000;
        }
    }
    .apply-form {
        .apply-tit {
            margin-top: 20px;
            margin-bottom: 10px;
            font-weight: 800;
            color: #000;
        }
        .apply-relevance {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .apply-btngroup {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 20px;
            box-shadow: 0px 0px 5px #888888;
            background: #fff;
            margin-top: 20px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}

.ant-drawer-body {
    .apply-spin {
        .apply-drawer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .ant-row {
                display: flex;
            }
            .ant-form-item {
                .ant-form-item-label {
                    width: 165px;
                }
            }
        }
        .apply-button {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 60px;
            box-shadow: 0px 0px 5px #888888;
            position: fixed;
            bottom: 0;
            right: 0;
        }
    }
}

.underline {
    height: 2px;
    background: #f0f2f5;
    width: calc(100% + 40px);
    margin-left: -20px;
}

.replenish-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60px;
    box-shadow: 0px 0px 5px #888888;
    position: fixed;
    bottom: 0;
    right: 0;
}

//查看协议
.examine-wrap {
    .examine-title {
        font-weight: 800;
        color: #000;
        font-size: 20px;
        margin-bottom: 15px;
    }
}