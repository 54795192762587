.redact {
  .redact-top {
    margin: 50px 30px 15px;
    .redact-title {
      font-size: 14px;
    }
  }
  .redact-content {
    .redact-tit {
      font-size: 14px;
      margin-left: 32px;
      padding-top: 15px;
      padding-bottom: 5px;
    }
    .redact-wangEditor {
      margin-top: 10px;
      // height: 1200px;
    }
  }
  .redact-btn {
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 2px -2px 2px #eee;
    text-align: right;
    padding-right: 20px;
  }
}

.w-e-text-container{
  height: 700px !important;
}